/////////////////////////////////////////
// デフォルト文字色を黒に指定
#SettingTagsTable.handsontable .htDimmed {
  color: #000;
}
// settingcenter 左上ヘッダーのした線を消す
#SettingTagsTable.handsontable thead tr th:first-child {
  border-bottom: none
}
// settingcenter 列ヘッダーの背景色デフォルト
#SettingTagsTable.handsontable thead th {
  background: #386ba7;
  color: white;
}
// settingitem 右側線はデフォルト破線する
#SettingTagsTable.handsontable th {
  border-right: 1px dashed #ccc;
  vertical-align: bottom;
  // padding: 3px 2px 2px 2px !important;
}
#SettingTagsTable.handsontable td {
  border-right: 1px dashed #ccc;
  vertical-align: middle;
  padding: 3px 2px 2px 2px !important;
}

#SettingTagsTable.handsontable td.color {
  border-right: 1px dashed #ccc;
  padding: 0px !important;
}
#SettingTagsTable.handsontable input.color {
  width: 78%;
  height: 21px;
  border: none;
  background: none;
  padding: 0px !important;
  margin-left: 1px;
}
// settingcenter 列ヘッダー背景色
#SettingTagsTable.handsontable .header {
  background: #386ba7 !important;
  color: white;
}

#SettingTagsTable.handsontable .rowHeader {
}
#SettingTagsTable.handsontable .rowHeader div{
  border-radius: 10px;
  border: 1px solid #bdbdbd;
  text-align: center;
}
//settingcenter 入力可能
#SettingTagsTable.handsontable .cell_editable {
  // background: #FFFFE0;
  border: 3px #ccc double;
  padding: 0px 1px 0px 1px !important;
}
//settingcenter 入力可能 選択時
#SettingTagsTable.handsontable .cell_editable.currentRow, 
#SettingTagsTable.handsontable .cell_editable.area,
#SettingTagsTable.handsontable .cell_editable.current {
  // background: #F6F2B3;
}
//settingcenter 入力不可
#SettingTagsTable.handsontable .cell_uneditable {
  // background: #E8E8E8;
}
//settingcenter 入力不可 選択時
#SettingTagsTable.handsontable .cell_uneditable.currentRow, 
#SettingTagsTable.handsontable .cell_uneditable.area,
#SettingTagsTable.handsontable .cell_uneditable.current {
  // background: #D7D3C7;
}
//settingcenter 入力エラー
#SettingTagsTable.handsontable .cell_error {
  background: #FFCCCC;
}
//settingcenter 編集済
#SettingTagsTable.handsontable .cell_edited {
  background: #A9D08E;
}
//settingcenter 入力エラー 選択時
#SettingTagsTable.handsontable .cell_error.currentRow, 
#SettingTagsTable.handsontable .cell_error.area,
#SettingTagsTable.handsontable .cell_error.current {
  background: #F6BFB3;
}
