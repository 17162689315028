/////////////////////////////////////////
/// #MainPageTable
/////////////////////////////////////////

// デフォルト文字色を黒に指定
#MainPageTable.handsontable .htDimmed {
  color: #000;
}
// mainpage 左上ヘッダーのした線を消す
#MainPageTable.handsontable thead tr th:first-child {
  border-bottom: none
}
// mainpage 列ヘッダーの背景色デフォルト
#MainPageTable.handsontable thead th {
  background: #386ba7;
  color: white;
}
// mainpage 右側線はデフォルト破線する
#MainPageTable.handsontable th {
  border-right: 1px dashed #ccc;
  vertical-align: bottom;
  // font-family: "ＭＳ Ｐゴシック", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Helvetica Neue", Arial, sans-serif !important;
  height: 13px !important;
  line-height: 12px !important;  
  // padding: 3px 2px 2px 2px !important;
} 
#MainPageTable.handsontable td {
  border-right: 1px dashed #ccc;
  vertical-align: middle;
  // font-family: "ＭＳ Ｐゴシック", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Helvetica Neue", Arial, sans-serif !important;
  height: 13px !important;
  line-height: 12px !important;  
  padding: 3px 2px 2px 2px !important;
}

#MainPageTable.handsontable th.rowHeader_weather,
#MainPageTable.handsontable td.rowHeader_weather,
#MainPageTable.handsontable .body_weather {
  height: 24px !important;
}

#MainPageTable.handsontable .mainpage_T { //期間
  background: #F2F2F2 !important;
  color: black;
}
#MainPageTable.handsontable .mainpage_T_right { //期間
  background: #F2F2F2 !important;
  color: black;
  text-align: right;
}
#MainPageTable.handsontable .mainpage_T_past { //過去
  background: #777777 !important;
  color: white;
}
#MainPageTable.handsontable .mainpage_T_past_week { //過去(週)
  background: #333 !important;
  color: white;
}
#MainPageTable.handsontable .mainpage_T_today { //今日
  background: #ff0 !important;
  color: black;
}
#MainPageTable.handsontable .mainpage_T_future { //未来
}
#MainPageTable.handsontable .mainpage_T_sunday_or_holiday { //祝日 or 日曜日
  background: #FF5050 !important;
  color: white;
}
#MainPageTable.handsontable .mainpage_T_saturday { //土曜日
  background: #5050FF !important;
  color: white;
}
// mainpage 列ヘッダー背景色
#MainPageTable.handsontable .header {
  background: #386ba7 !important;
  color: white;
}
// orderinstruction 列ヘッダー 下線なし
#MainPageTable.handsontable .header_noBorder {
  background: #386ba7 !important;
  color: white;
  border-bottom: 1px #386ba7 solid;
}
// settingitem 列ヘッダー背景色
#MainPageTable.handsontable .header_no { //No.
  background: #333 !important;
  color: white;
}
// settingitem 列ヘッダー 下線なし
#MainPageTable.handsontable .header_noBorder_no { //No.
  background: #333 !important;
  color: white;
  border-bottom: 1px #333 solid;
}
#MainPageTable.handsontable .row_header_group_parent { //グループ行
  background: #111 !important;
  color: #FFF;
}
#MainPageTable.handsontable .row_header_group { //グループ行
  background: #D6DCE4 !important;
}
#MainPageTable.handsontable .row_header_total { //合計行
  background: #E1EBE5 !important;
}
#MainPageTable.handsontable .row_header_info { //明細行
  background: #F2F2F2;
}
#MainPageTable.handsontable .row_header_info_drilldown { //明細行
  background: #E9F2DF;
  text-align: right;
}
#MainPageTable.handsontable .rowHeader {
  color: black;
}
#MainPageTable.handsontable .rowHeader_storeSales {   //"店頭売上",
  color: #C65911;
}
#MainPageTable.handsontable .rowHeader_transfer {     //"転送予定",
  color: #00B0F0;
}
#MainPageTable.handsontable .rowHeader_warehousing {  //"入庫",
  color: #3333FF;
}
#MainPageTable.handsontable .rowHeader_warehousing_last {  //"入庫",
  color: #3333FF;
}
#MainPageTable.handsontable .rowHeader_bestBefore {   //"賞味期限",
  color: #7030A0;
}
#MainPageTable.handsontable .rowHeader_storeOrder {   //"受注",
  color: #333333;
}
#MainPageTable.handsontable .rowHeader_shipping {     //"出庫",
  color: #000000;
}
#MainPageTable.handsontable .rowHeader_shipping_storeOrder {     //"出庫(先付含)",
  color: #000000;
}
#MainPageTable.handsontable .rowHeader_shipping_last {     //"出庫(先付含)",
  color: #000000;
}
#MainPageTable.handsontable .rowHeader_stockNormal {  //"在庫（良品）",
  color: #CC6600;
}
#MainPageTable.handsontable .rowHeader_stockUseUp {   //"在庫（出切）",
  color: #948A54;
}
#MainPageTable.handsontable .rowHeader_stockReturned {//"在庫（返品）",
  color: #948A54;
}
#MainPageTable.handsontable .rowHeader_similarItem_warehousing {  //"入庫",
  color: #3333FF;
  background: #ddd;
}
#MainPageTable.handsontable .rowHeader_similarItem_shipping {     //"出庫",
  color: #000000;
  background: #ddd;
}
#MainPageTable.handsontable .rowHeader_similarItem_stockNormal {  //"在庫（良品）",
  color: #CC6600;
  background: #ddd;
}
//明細行 Body
#MainPageTable.handsontable .body_storeSales {   //"店頭売上",
}
#MainPageTable.handsontable .body_transfer {     //"転送予定",
}
#MainPageTable.handsontable .body_warehousing {  //"入庫",
}
#MainPageTable.handsontable .body_bestBefore {   //"賞味期限",
  font-size: 10px;
  text-align: center;
}
#MainPageTable.handsontable .body_storeOrder {   //"受注",
}
#MainPageTable.handsontable .body_shipping {     //"出庫",
}
#MainPageTable.handsontable .body_shipping_storeOrder {     //"出庫(先付含)",
}
#MainPageTable.handsontable .body_shipping_group1 { //明細行
  background: #E9F2DF;
}
#MainPageTable.handsontable .body_shipping_last {     //"出庫(先付含)",
  background: #E2E2E2;
}
#MainPageTable.handsontable .body_warehousing_last {     //"出庫(先付含)",
  background: #E2E2E2;
}
#MainPageTable.handsontable .body_stockNormal {  //"在庫（良品）",
  background: #90FF90;
  font-weight: bold;
}
#MainPageTable.handsontable .body_stockNormal1 {  //"在庫（良品）",
}
#MainPageTable.handsontable .body_stockNormal2 {  //"在庫（良品）",
}
#MainPageTable.handsontable .body_stockNormal3 {  //"在庫（良品）",
}
#MainPageTable.handsontable .body_stockUseUp {   //"在庫（出切）",
}
#MainPageTable.handsontable .body_stockReturned {//"在庫（返品）",
}
#MainPageTable.handsontable .body_similarItem_warehousing {  //"入庫",
  background: #EEE;
}
#MainPageTable.handsontable .body_similarItem_shipping {     //"出庫",
  background: #EEE;
}
#MainPageTable.handsontable .body_similarItem_stockNormal {  //"在庫（良品）",
  background: #DED;
}
#MainPageTable.handsontable .body_weather {//天気
  padding: 0;
}

#MainPageTable.handsontable .body_highlight_green {  //ハイライト
  background: #50FF50 !important;
}
#MainPageTable.handsontable .body_highlight_light_green {  //ハイライト
  background: #90FF90 !important;
}
#MainPageTable.handsontable .body_highlight_yellow {  //ハイライト
  background: #FFFF50 !important;
}
#MainPageTable.handsontable .body_highlight_light_yellow {  //ハイライト
  background: #FFFF90 !important;
}
#MainPageTable.handsontable .body_highlight_red {  //ハイライト
  background: #FF5050 !important;
}
#MainPageTable.handsontable .body_highlight_light_red {  //ハイライト
  background: #FF9090 !important;
}
#MainPageTable.handsontable .body_highlight_blue {  //ハイライト
  background: #5050FF !important;
}
#MainPageTable.handsontable .body_highlight_cyan {  //ハイライト
  background: #50FFFF !important;
}
#MainPageTable.handsontable .body_highlight_light_cyan {  //ハイライト
  background: #B0FFFF !important;
}
#MainPageTable.handsontable .body_highlight_magenda {  //ハイライト
  background: #FF50FF !important;
}
#MainPageTable.handsontable .body_highlight_matsushita {  //ハイライト(松下さんカラー）
  background: #F2D6D8 !important;
}
#MainPageTable.handsontable .body_highlight_purple {  //ハイライト
  background: #5f4979 !important;
  color: #fff !important;
}

// mainpage セル文字色
#MainPageTable.handsontable .cell_bold { //太字
  font-weight: bold;
}
#MainPageTable.handsontable .cell_border_bottom_none {
  border-bottom: 1px transparent solid !important;
}
#MainPageTable.handsontable .cell_border_bottom_bold {
  border-bottom: 1px black solid !important;
}
#MainPageTable.handsontable .cell_border_bottom_bold2 {
  border-bottom: 2px black solid !important;
}
#MainPageTable.handsontable .cell_border_top_bold {
  border-top: 1px black solid !important;
}
#MainPageTable.handsontable .cell_border_right_bold {
  border-right: 1px black solid !important;
}
#MainPageTable.handsontable .cell_border_right_bold_white {
  border-right: 1px white solid !important;
}
#MainPageTable.handsontable .cell_border_right_bold2 {
  border-right: 2px black solid !important;
}
#MainPageTable.handsontable .cell_border_right_bold3 {
  border-right: 3px black double !important;
}
#MainPageTable.handsontable .cell_border_left_bold {
  border-left: 1px black solid !important;
}
#MainPageTable.handsontable .cell_border_left_bold_white {
  border-left: 1px white solid !important;
}
#MainPageTable.handsontable .cell_border_left_bold2 {
  border-left: 2px black solid !important;
}
#MainPageTable.handsontable .cell_fg_none {
  color: transparent !important;
}
//mainpage 状態(暫定発注数)
#MainPageTable.handsontable .cell_state_zantei {
  color: #385723;
}
//mainpage 状態(実績)
#MainPageTable.handsontable .cell_state_result {
  color: black;
}
//mainpage 状態(計算)
#MainPageTable.handsontable .cell_state_calc {
  color: #000;
}
//mainpage 状態(入力)
#MainPageTable.handsontable .cell_state_editOwn {
  font-style: italic;
  color: #3333FF;
  font-weight: bold;
}
#MainPageTable.handsontable .cell_state_editOther {
  font-style: italic;
  color: #AA33FF;
}
#MainPageTable.handsontable .cell_state_editMix {
  font-style: italic;
  color: #7733FF;
  font-weight: bold;
}
#MainPageTable.handsontable .cell_state_editAuto {
  font-style: italic;
  color: #009900;
  font-weight: bold;
}
#MainPageTable.handsontable .cell_state_weatherAlert {
  background: #FFCCFF !important;
}
//mainpage コメントセル（通常コメントは非表示）
#MainPageTable.handsontable .htCommentCell:after {
  border-top: none;
  border-left: none;
}
//mainpage コメントセル（コメントあり）
#MainPageTable.handsontable .htCommentCell_comment_own:after {
  content: "🗬";
  top: -2px;
  right: auto;
  left: 1px;
  font-size: 8pt;
  color: #5050FF;
}
#MainPageTable.handsontable .htCommentCell_comment_other:after {
  content: "🗬";
  top: -2px;
  right: auto;
  left: 1px;
  font-size: 8pt;
  color: #AA33FF;
}

//mainpage 入力可能
#MainPageTable.handsontable .cell_editable {
  // background: #FFFFE0;
  border: 3px #ccc double;
  padding: 0px 1px 0px 1px !important;
}
//mainpage 入力可能 選択時
#MainPageTable.handsontable .cell_editable.currentRow, 
#MainPageTable.handsontable .cell_editable.area,
#MainPageTable.handsontable .cell_editable.current {
  // background: #F6F2B3;
}
//mainpage 入力不可
#MainPageTable.handsontable .cell_uneditable {
  // background: #E8E8E8;
}
//mainpage 入力不可 選択時
#MainPageTable.handsontable .cell_uneditable.currentRow, 
#MainPageTable.handsontable .cell_uneditable.area,
#MainPageTable.handsontable .cell_uneditable.current {
  // background: #D7D3C7;
}
//mainpage 選択行
#MainPageTable.handsontable .cell_current {
  background: #FFFFCC;
}
//mainpage 入力エラー
#MainPageTable.handsontable .cell_error {
  background: #FFCCCC;
}
//mainpage 警告
#MainPageTable.handsontable .cell_warn {
  background: #FFB62F;
}
//mainpage 編集済
#MainPageTable.handsontable .cell_edited {
  background: #A9D08E;
}
//mainpage 入力エラー 選択時
#MainPageTable.handsontable .cell_error.currentRow, 
#MainPageTable.handsontable .cell_error.area,
#MainPageTable.handsontable .cell_error.current {
  background: #F6BFB3;
}
//mainpage 警告 選択時
#MainPageTable.handsontable .cell_warn.currentRow, 
#MainPageTable.handsontable .cell_warn.area,
#MainPageTable.handsontable .cell_warn.current {
  background: #df9f29;
}

//メーカー残在庫なしアラート
#MainPageTable.handsontable .cell_maker_limit_alert{
  animation: cell_maker_limit_alert_flash 1s linear infinite;
  background:#831083;
  color:#FFFF00;
}

//許容期限切れアラート
#MainPageTable.handsontable .cell_freshness_alert{
  // animation: cell_maker_limit_alert_flash 1s linear infinite;
  background:#505050;
  color: #fff;
}

#MainPageTable.handsontable .hiddenButton {
  background: transparent !important;
  color: transparent !important;
  border: 1px solid transparent !important;
}
#MainPageTable.handsontable .hiddenButton:hover {
  pointer-events: none;
}

#MainPageTable.handsontable .weekFold {
  background: #eee;
  border-radius: 2px;
  border: 1px solid #bbb;
  font-size: 9px;
  line-height: 9px;
  padding: 2px;
  margin: 0px 0px 3px 0px;
  float: right;
}
#MainPageTable.handsontable .weekFold:before {
  content: "　 ";
}
#MainPageTable.handsontable .expandButton:before {
  content: "＋ ";
}
#MainPageTable.handsontable .collapseButton:before {
  content: "－ ";
}
#MainPageTable.handsontable td span {
  font-size: smaller;
  padding: 0 0.2em;
  position: relative;
}
#MainPageTable.handsontable td span::after {
  content: '';
  position: absolute;
  display: block;
  top: 47%;
  left: -0.1em;
  transform: translateY(-40%);
  width: 0.9em;
  height: 0.9em;
  border: 1px solid #454545;
}
#MainPageTable.handsontable .cell_state_editOwn span::after {
  left: 0em;
  border: solid 1px #3333FF;
}
#MainPageTable.handsontable .cell_state_editOther span::after{
  border: solid 1px #AA33FF;
}
#MainPageTable.handsontable .cell_state_editMix span::after{
  left: 0em;
  border: solid 1px #7733FF;
}
#MainPageTable.handsontable .cell_state_editAuto span::after{
  left: 0em;
  border: solid 1px #009900;
}
#MainPageTable.handsontable .body_highlight_purple.cell_state_editOwn span::after {
  left: 0em;
  border: solid 1px #fff !important;
}

@keyframes cell_maker_limit_alert_flash {
  0% {
    opacity: 1.0;
  }
  50% {
    opacity: 0.3;
  }
  100% {
    opacity: 1.0;
  }
}


/////////////////////////////////////////
/// #MainPageShukkaHistoryTable
/////////////////////////////////////////

// デフォルト文字色を黒に指定
#MainPageShukkaHistoryTable.handsontable .htDimmed {
  color: #000;
}
#MainPageShukkaHistoryTable.handsontable thead th {
  background: #386ba7;
  color: white;
}
// mainpage 右側線はデフォルト破線する
#MainPageShukkaHistoryTable.handsontable th {
  border-right: 1px dashed #ccc;
  vertical-align: bottom;
} 
#MainPageShukkaHistoryTable.handsontable td {
  border-right: 1px dashed #ccc;
  vertical-align: middle;
}
#MainPageShukkaHistoryTable.handsontable .cell_border_bottom_none {
  border-bottom: 1px transparent solid !important;
}
#MainPageShukkaHistoryTable.handsontable .cell_border_bottom_dotted {
  border-bottom: 1px #ccc dotted !important;
}
#MainPageShukkaHistoryTable.handsontable .cell_border_right_bold2 {
  border-right: 2px black solid !important;
}
#MainPageShukkaHistoryTable.handsontable .cell_border_bottom_bold2 {
  border-bottom: 2px black solid !important;
}
#MainPageShukkaHistoryTable.handsontable .mainpage_T_sunday { //日曜日
  background: #f00 !important;
  color: white;
}
#MainPageShukkaHistoryTable.handsontable .mainpage_T_saturday { //土曜日
  background: #00f !important;
  color: white;
}
#MainPageShukkaHistoryTable.handsontable .mainpage_T_today { //今日
  background: #ff0 !important;
  color: black;
}
#MainPageShukkaHistoryTable.handsontable .cell_border_right_bold {
  border-right: 1px black solid !important;
}
#MainPageShukkaHistoryTable.handsontable .cell_border_right_bold_white {
  border-right: 1px white solid !important;
}
/////////////////////////////////////////
/// #MainPageStoreListTable
/////////////////////////////////////////

// デフォルト文字色を黒に指定
#MainPageStoreListTable.handsontable .htDimmed {
  color: #000;
}
#MainPageStoreListTable.handsontable thead th {
  background: #386ba7;
  color: white;
}
// mainpage 右側線はデフォルト破線する
#MainPageStoreListTable.handsontable th {
  border-right: 1px dashed #ccc;
  vertical-align: bottom;
}
#MainPageStoreListTable.handsontable td {
  border-right: 1px dashed #ccc;
  vertical-align: middle;
}
#MainPageStoreListTable.handsontable .row_header_group { //グループ行
  background: #D6DCE4 !important;
}
#MainPageStoreListTable.handsontable .cell_bold { //太字
  font-weight: bold;
}
#MainPageStoreListTable.handsontable .cell_border_right_bold {
  border-right: 1px black solid !important;
}
#MainPageStoreListTable.handsontable .cell_border_right_bold_white {
  border-right: 1px white solid !important;
}

/////////////////////////////////////////
/// #MainPageAutoOrderTable
/////////////////////////////////////////

// デフォルト文字色を黒に指定
#MainPageAutoOrderTable.handsontable .htDimmed {
  color: #000;
}
#MainPageAutoOrderTable.handsontable thead th {
  background: #386ba7;
  color: white;
}
// mainpage 右側線はデフォルト破線する
#MainPageAutoOrderTable.handsontable th {
  border-right: 1px dashed #ccc;
  vertical-align: bottom;
}
#MainPageAutoOrderTable.handsontable td {
  border-right: 1px dashed #ccc;
  vertical-align: middle;
}
#MainPageAutoOrderTable.handsontable .cell_border_right_bold {
  border-right: 1px black solid !important;
}
#MainPageAutoOrderTable.handsontable .cell_border_right_bold_white {
  border-right: 1px white solid !important;
}

/////////////////////////////////////////
/// #MainPageProvisionalTable
/////////////////////////////////////////

// デフォルト文字色を黒に指定
#MainPageProvisionalTable.handsontable .htDimmed {
  color: #000;
}
#MainPageProvisionalTable.handsontable thead th {
  background: #386ba7;
  color: white;
}
// mainpage 右側線はデフォルト破線する
#MainPageProvisionalTable.handsontable th {
  border-right: 1px dashed #ccc;
  vertical-align: bottom;
}
#MainPageProvisionalTable.handsontable td {
  border-right: 1px dashed #ccc;
  vertical-align: middle;
}
#MainPageProvisionalTable.handsontable .cell_border_right_bold {
  border-right: 1px black solid !important;
}
#MainPageProvisionalTable.handsontable .cell_border_right_bold_white {
  border-right: 1px white solid !important;
}

/////////////////////////////////////////
/// #MainPageOrderHistoryTable
/////////////////////////////////////////

// デフォルト文字色を黒に指定
#MainPageOrderHistoryTable.handsontable .htDimmed {
  color: #000;
}
#MainPageOrderHistoryTable.handsontable thead th {
  background: #386ba7;
  color: white;
}
// mainpage 右側線はデフォルト破線する
#MainPageOrderHistoryTable.handsontable th {
  border-right: 1px dashed #ccc;
  vertical-align: bottom;
}
#MainPageOrderHistoryTable.handsontable td {
  border-right: 1px dashed #ccc;
  vertical-align: middle;
}
#MainPageOrderHistoryTable.handsontable .cell_border_right_bold {
  border-right: 1px black solid !important;
}
#MainPageOrderHistoryTable.handsontable .cell_border_right_bold_white {
  border-right: 1px white solid !important;
}

