/////////////////////////////////////////
// デフォルト文字色を黒に指定
#SettingTransferCenterTable.handsontable .htDimmed {
  color: #000;
}
// settingtransfercenter 左上ヘッダーのした線を消す
// #SettingTransferCenterTable.handsontable thead tr th:first-child {
//   border-bottom: none
// }
// settingtransfercenter 列ヘッダーの背景色デフォルト
#SettingTransferCenterTable.handsontable thead th {
  background: #386ba7;
  color: white;
}
// settingtransfercenter 右側線はデフォルト破線する
#SettingTransferCenterTable.handsontable th {
  border-right: 1px dashed #ccc;
  vertical-align: bottom;
  // padding: 3px 2px 2px 2px !important;
}
#SettingTransferCenterTable.handsontable td {
  border-right: 1px dashed #ccc;
  vertical-align: middle;
  padding: 3px 2px 2px 2px !important;
}
// settingtransfercenter 列ヘッダー背景色
#SettingTransferCenterTable.handsontable .header {
  background: #386ba7 !important;
  color: white;
}
// settingtransfercenter 列ヘッダー 下線なし
#SettingTransferCenterTable.handsontable .header_noBorder {
  background: #386ba7 !important;
  color: white;
  border-bottom: 1px #386ba7 solid;
}
// settingitem 列ヘッダー背景色
#SettingTransferCenterTable.handsontable .header_no { //No.
  background: #333 !important;
  color: white;
}
// settingitem 列ヘッダー 下線なし
#SettingTransferCenterTable.handsontable .header_noBorder_no { //No.
  background: #333 !important;
  color: white;
  border-bottom: 1px #333 solid;
}
// settingitem 列ヘッダー背景色
#SettingTransferCenterTable.handsontable .header_target { //対象
  background: #559 !important;
  color: white;
}
// settingitem 列ヘッダー 下線なし
#SettingTransferCenterTable.handsontable .header_noBorder_target { //対象
  background: #559 !important;
  color: white;
  border-bottom: 1px #559 solid;
}
#SettingTransferCenterTable.handsontable .row_header_group { //グループ行
  background: #D6DCE4 !important;
}
#SettingTransferCenterTable.handsontable .row_header_total { //合計行
  background: #E1EBE5 !important;
}
#SettingTransferCenterTable.handsontable .row_header_info { //明細行
  // background: #F2F2F2;
}
#SettingTransferCenterTable.handsontable .rowHeader {
}
#SettingTransferCenterTable.handsontable .rowHeader div{
  border-radius: 10px;
  border: 1px solid #bdbdbd;
  text-align: center;
}
// settingtransfercenter セル文字色
#SettingTransferCenterTable.handsontable .cell_bold { //太字
  font-weight: bold;
}
#SettingTransferCenterTable.handsontable .cell_border_bottom_none {
  border-bottom: 1px transparent solid !important;
}
#SettingTransferCenterTable.handsontable .cell_border_bottom_bold {
  border-bottom: 1px black solid !important;
}
#SettingTransferCenterTable.handsontable .cell_border_bottom_bold2 {
  border-bottom: 1px black solid !important;
}
#SettingTransferCenterTable.handsontable .cell_border_top_bold {
  border-top: 1px black solid !important;
}
#SettingTransferCenterTable.handsontable .cell_border_right_bold {
  border-right: 1px black solid !important;
}
#SettingTransferCenterTable.handsontable .cell_border_right_bold_white {
  border-right: 1px white solid !important;
}
#SettingTransferCenterTable.handsontable .cell_border_right_bold2 {
  border-right: 2px black solid !important;
}
#SettingTransferCenterTable.handsontable .cell_border_left_bold {
  border-left: 1px black solid !important;
}
#SettingTransferCenterTable.handsontable .cell_border_left_bold_white {
  border-left: 1px white solid !important;
}
#SettingTransferCenterTable.handsontable .cell_border_left_bold2 {
  border-left: 2px black solid !important;
}
#SettingTransferCenterTable.handsontable .cell_fg_gray {
  color: #BBB !important;
}
#SettingTransferCenterTable.handsontable .cell_fg_red {
  color: #F00 !important;
}
#SettingTransferCenterTable.handsontable .cell_fg_blue {
  color: #7491CD !important;
}
#SettingTransferCenterTable.handsontable .cell_fg_none {
  color: transparent !important;
}
//settingtransfercenter 連携対象
#SettingTransferCenterTable.handsontable .cell_target {
  background: #E0E0FF;
  accent-color: #559;
}
//settingtransfercenter 入力可能
#SettingTransferCenterTable.handsontable .cell_editable {
  // background: #FFFFE0;
  border: 3px #ccc double;
  padding: 0px 1px 0px 1px !important;
}
//settingtransfercenter 入力可能 選択時
#SettingTransferCenterTable.handsontable .cell_editable.currentRow, 
#SettingTransferCenterTable.handsontable .cell_editable.area,
#SettingTransferCenterTable.handsontable .cell_editable.current {
  // background: #F6F2B3;
}
//settingtransfercenter 入力不可
#SettingTransferCenterTable.handsontable .cell_uneditable {
  // background: #E8E8E8;
}
//settingtransfercenter 入力不可 選択時
#SettingTransferCenterTable.handsontable .cell_uneditable.currentRow, 
#SettingTransferCenterTable.handsontable .cell_uneditable.area,
#SettingTransferCenterTable.handsontable .cell_uneditable.current {
  // background: #D7D3C7;
}
//settingtransfercenter 入力エラー
#SettingTransferCenterTable.handsontable .cell_error {
  background: #FFCCCC;
}
//settingtransfercenter 警告
#SettingTransferCenterTable.handsontable .cell_warn {
  background: #FFB62F;
}
//settingtransfercenter 編集済
#SettingTransferCenterTable.handsontable .cell_edited {
  background: #A9D08E;
}
//settingtransfercenter 入力エラー 選択時
#SettingTransferCenterTable.handsontable .cell_error.currentRow, 
#SettingTransferCenterTable.handsontable .cell_error.area,
#SettingTransferCenterTable.handsontable .cell_error.current {
  background: #F6BFB3;
}
//settingtransfercenter 警告 選択時
#SettingTransferCenterTable.handsontable .cell_warn.currentRow, 
#SettingTransferCenterTable.handsontable .cell_warn.area,
#SettingTransferCenterTable.handsontable .cell_warn.current {
  background: #df9f29;
}
