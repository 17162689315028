/////////////////////////////////////////
// デフォルト文字色を黒に指定
#SettingLimitTable.handsontable .htDimmed {
  color: #000;
}
// settinglimit 左上ヘッダーのした線を消す
// #SettingLimitTable.handsontable thead tr th:first-child {
//   border-bottom: none
// }
// settinglimit 列ヘッダーの背景色デフォルト
#SettingLimitTable.handsontable thead th {
  background: #386ba7;
  color: white;
}
// settinglimit 右側線はデフォルト破線する
#SettingLimitTable.handsontable th {
  border-right: 1px dashed #ccc;
  vertical-align: bottom;
  // padding: 3px 2px 2px 2px !important;
}
#SettingLimitTable.handsontable td {
  border-right: 1px dashed #ccc;
  vertical-align: middle;
  padding: 3px 2px 2px 2px !important;
}
// settinglimit 列ヘッダー背景色
#SettingLimitTable.handsontable .header {
  background: #386ba7 !important;
  color: white;
}
// settinglimit 列ヘッダー 下線なし
#SettingLimitTable.handsontable .header_noBorder {
  background: #386ba7 !important;
  color: white;
  border-bottom: 1px #386ba7 solid;
}
// settinglimit 列ヘッダー背景色
#SettingLimitTable.handsontable .header_no { //No.
  background: #333 !important;
  color: white;
}
// settinglimit 列ヘッダー 下線なし
#SettingLimitTable.handsontable .header_noBorder_no { //No.
  background: #333 !important;
  color: white;
  border-bottom: 1px #333 solid;
}
#SettingLimitTable.handsontable .header_bulk { //一括変更
  background: #595 !important;
  color: white;
}
#SettingLimitTable.handsontable .header_noBorder_bulk { //一括変更 下線なし
  background: #595 !important;
  color: white;
  border-bottom: 1px #595 solid;
}
#SettingLimitTable.handsontable .header_makercenter { //メーカー倉庫
  background: #995 !important;
  color: white;
}
#SettingLimitTable.handsontable .header_noBorder_makercenter { //メーカー倉庫
  background: #995 !important;
  color: white;
  border-bottom: 1px #995 solid;
}

#SettingLimitTable.handsontable .col_header_bulk { //一括変更
  background: #D6E4DC;
  accent-color: #595;
}
#SettingLimitTable.handsontable .row_header_group { //グループ行
  background: #D6DCE4 !important;
}
#SettingLimitTable.handsontable .row_header_total { //合計行
  background: #E1EBE5 !important;
}
#SettingLimitTable.handsontable .row_header_info { //明細行
  background: #F2F2F2;
}
#SettingLimitTable.handsontable .rowHeader {
}
#SettingLimitTable.handsontable .rowHeader div{
  border-radius: 10px;
  border: 1px solid #bdbdbd;
  text-align: center;
}
// settinglimit セル文字色
#SettingLimitTable.handsontable .cell_bold { //太字
  font-weight: bold;
}
#SettingLimitTable.handsontable .cell_border_bottom_none {
  border-bottom: 1px transparent solid !important;
}
#SettingLimitTable.handsontable .cell_border_bottom_bold {
  border-bottom: 1px black solid !important;
}
#SettingLimitTable.handsontable .cell_border_bottom_bold2 {
  border-bottom: 2px black solid !important;
}
#SettingLimitTable.handsontable .cell_border_top_bold {
  border-top: 1px black solid !important;
}
#SettingLimitTable.handsontable .cell_border_right_bold {
  border-right: 1px black solid !important;
}
#SettingLimitTable.handsontable .cell_border_right_bold_white {
  border-right: 1px white solid !important;
}
#SettingLimitTable.handsontable .cell_border_right_bold2 {
  border-right: 2px black solid !important;
}
#SettingLimitTable.handsontable .cell_border_left_bold {
  border-left: 1px black solid !important;
}
#SettingLimitTable.handsontable .cell_border_left_bold_white {
  border-left: 1px white solid !important;
}
#SettingLimitTable.handsontable .cell_border_left_bold2 {
  border-left: 2px black solid !important;
}
#SettingLimitTable.handsontable .cell_fg_gray {
  color: #BBB !important;
}
#SettingLimitTable.handsontable .cell_fg_red {
  color: #F00 !important;
}
#SettingLimitTable.handsontable .cell_fg_blue {
  color: #7491CD !important;
}
#SettingLimitTable.handsontable .cell_fg_none {
  color: transparent !important;
}
//settinglimit 入力可能
#SettingLimitTable.handsontable .cell_editable {
  // background: #FFFFE0;
  border: 3px #ccc double;
  padding: 0px 1px 0px 1px !important;
}
//settinglimit 入力可能 選択時
#SettingLimitTable.handsontable .cell_editable.currentRow, 
#SettingLimitTable.handsontable .cell_editable.area,
#SettingLimitTable.handsontable .cell_editable.current {
  // background: #F6F2B3;
}
//settinglimit 入力不可
#SettingLimitTable.handsontable .cell_uneditable {
  // background: #E8E8E8;
}
//settinglimit 入力不可 選択時
#SettingLimitTable.handsontable .cell_uneditable.currentRow, 
#SettingLimitTable.handsontable .cell_uneditable.area,
#SettingLimitTable.handsontable .cell_uneditable.current {
  // background: #D7D3C7;
}
//settinglimit 入力エラー
#SettingLimitTable.handsontable .cell_error {
  background: #FFCCCC;
}
//settinglimit 警告
#SettingLimitTable.handsontable .cell_warn {
  background: #FFB62F;
}
//settinglimit 編集済
#SettingLimitTable.handsontable .cell_edited {
  background: #A9D08E;
}
//settinglimit 入力エラー 選択時
#SettingLimitTable.handsontable .cell_error.currentRow, 
#SettingLimitTable.handsontable .cell_error.area,
#SettingLimitTable.handsontable .cell_error.current {
  background: #F6BFB3;
}
//settinglimit 警告 選択時
#SettingLimitTable.handsontable .cell_warn.currentRow, 
#SettingLimitTable.handsontable .cell_warn.area,
#SettingLimitTable.handsontable .cell_warn.current {
  background: #df9f29;
}
#SettingLimitTable.handsontable .cell_target { //一括変更
  accent-color: #595;
}
#SettingLimitTable.handsontable .cell_valid { //有効
  accent-color: #559;
}
